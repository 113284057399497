import * as BABYLON from "babylonjs";
import greedyMeshing, { GreedyMesherResult } from "#root/game/greedyMeshing";
import { VertexData } from "babylonjs";
import { WorldPlace, WorldJoinData } from "#mws/interfaces";
import WebSocketManager from "#root/lib/webSocketManager";
import { Endpoints } from "#mws/data";
import nodegzip from "node-gzip";

export interface VoxelDefinition {
  materialId: number;
  textureCoords: [number, number];
}

export interface ChunkVoxelPosition {
  chunkIndex: number;
  voxelPosition: BABYLON.Vector3;
}

export interface RaycastResult {
  voxelId: number;
  worldPosition: BABYLON.Vector3;
}

export class VoxelChunk {
  private engine: VoxelEngine;
  private position: BABYLON.Vector3;
  private meshes: BABYLON.Mesh[];
  private voxels: Uint16Array;
  private meshMaterialIds: number[];

  constructor(
    engine: VoxelEngine,
    position: BABYLON.DeepImmutableObject<BABYLON.Vector3>
  ) {
    this.position = position.clone();
    this.engine = engine;
    this.meshes = new Array<BABYLON.Mesh>();
    this.meshMaterialIds = new Array<number>();
    this.voxels = new Uint16Array(
      engine.chunkSize.x * engine.chunkSize.y * engine.chunkSize.z
    );

    const meshPosition = this.position.multiply(engine.chunkSize);
    for (let i = 0; i < this.engine.materials.length; i++) {
      if (this.engine.materials[i] === null) continue;

      const mesh = new BABYLON.Mesh(
        `Mesh ${position.x}/${position.y}/${position.z}/${i}`,
        engine.node.getScene(),
        engine.node
      );
      mesh.material = engine.materials[i];
      mesh.position = meshPosition;
      mesh.checkCollisions = true;
      mesh.isPickable = true;
      // mesh.onRebuildObservable.add(() => {
      //   console.log("Rebuilt");
      //   mesh.physicsImpostor = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.MeshImpostor, {
      //     mass: 0,
      //     friction: 0.5,
      //     restitution: 0.5,
      //     ignoreParent: true
      //   }, this.engine.node.getScene());
      // });

      this.meshes.push(mesh);
      this.meshMaterialIds.push(i);
    }
  }

  public build(): void {
    const { vertices, faces } = this.engine.executeGreedyMesher(this.voxels, [
      this.engine.chunkSize.x,
      this.engine.chunkSize.y,
      this.engine.chunkSize.z,
    ]);

    const engineVoxelDefinitions = this.engine.voxelDefinitions;
    for (let i = 0; i < this.meshes.length; i++) {
      const positions = new Array<number>();
      const indices = new Array<number>();

      let v0: number, v1: number, v2: number, v3: number;
      const meshMaterialId = this.meshMaterialIds[i];
      for (const face of faces) {
        if (engineVoxelDefinitions[face[4]].materialId !== meshMaterialId)
          continue;

        v0 = face[0];
        v1 = face[1];
        v2 = face[2];
        v3 = face[3];
        positions.push(
          ...vertices[v0],
          ...vertices[v1],
          ...vertices[v2],
          ...vertices[v3]
        );
        indices.push(v2, v1, v0, v0, v3, v2);
      }

      if (positions.length === 0) {
        new VertexData().applyToMesh(this.meshes[i]);
        continue;
      }

      const normals = new Array<number>();
      BABYLON.VertexData.ComputeNormals(positions, indices, normals);

      const uvs = [];
      let voxelId: number;
      for (const face of faces) {
        voxelId = face[4];
        v0 = face[0];
        v1 = face[1];
        v2 = face[2];
        v3 = face[3];
        const facePositions = [
          new BABYLON.Vector3(...vertices[v0]),
          new BABYLON.Vector3(...vertices[v1]),
          new BABYLON.Vector3(...vertices[v2]),
          new BABYLON.Vector3(...vertices[v3]),
        ];
        const facePosition = facePositions[0].add(facePositions[1]);
        facePosition
          .addInPlace(facePositions[2])
          .addInPlace(facePositions[3])
          .scaleInPlace(0.25);

        for (let i = 0; i < 4; i++) {
          uvs.push(
            engineVoxelDefinitions[voxelId].textureCoords[0],
            engineVoxelDefinitions[voxelId].textureCoords[1]
          );
        }
      }

      const vertexData = new VertexData();
      vertexData.positions = positions;
      vertexData.indices = indices;
      vertexData.normals = normals;
      vertexData.uvs = uvs;

      vertexData.applyToMesh(this.meshes[i]);
      //this.meshes[i].setPivotPoint(this.engine.chunkSize.scale(0.5));
      this.meshes[i]._rebuild();
    }
  }

  private getBlockIndexFromChunkBlockPosition(
    chunkBlockPosition: BABYLON.Vector3
  ): number {
    return (
      chunkBlockPosition.z +
      this.engine.chunkSize.z *
        (chunkBlockPosition.x + this.engine.chunkSize.x * chunkBlockPosition.y)
    );
  }

  public setVoxel(chunkBlockPosition: BABYLON.Vector3, voxelId: number): void {
    const index = this.getBlockIndexFromChunkBlockPosition(chunkBlockPosition);
    this.voxels[index] = voxelId;
  }
}

export interface VoxelEngineOptions {
  voxels: VoxelDefinition[];
  materials: BABYLON.NodeMaterial[];
  voxelSetByMaterial: Map<number, Set<number>>;
}

export default class VoxelEngine {
  // Render data
  private _sceneNode: BABYLON.Nullable<BABYLON.Node> = null;
  private _materials = new Array<BABYLON.Material>();
  private _voxelDefinitions = new Array<VoxelDefinition>();
  private _chunkSize = new BABYLON.Vector3(16, 16, 16);
  private greedyMesher: (
    voxelData: Uint16Array,
    dims: number[]
  ) => { vertices: number[][]; faces: number[][] } = greedyMeshing(0);
  private _voxelSize = new BABYLON.Vector2(16, 16);

  public get materials(): BABYLON.Material[] {
    return this._materials;
  }
  public get chunkSize(): BABYLON.Immutable<BABYLON.Vector3> {
    return this._chunkSize;
  }
  public get voxelSize(): BABYLON.Immutable<BABYLON.Vector2> {
    return this._voxelSize;
  }
  public get voxelDefinitions(): VoxelDefinition[] {
    return this._voxelDefinitions;
  }
  public get node(): BABYLON.Node {
    return this._sceneNode as BABYLON.Node;
  }
  public get worldSize(): BABYLON.Immutable<BABYLON.Vector3> {
    return this._worldSize;
  }

  // World data
  private _worldSize = BABYLON.Vector3.Zero();
  private chunkCount = BABYLON.Vector3.Zero();

  // World data
  private chunks = new Array<VoxelChunk>();

  public registerMaterial(material: BABYLON.Material, index: number): void {
    this._materials[index] = material;
  }

  public registerVoxel(voxelDefinition: VoxelDefinition, index: number): void {
    this._voxelDefinitions[index] = voxelDefinition;
  }

  public executeGreedyMesher(
    voxelData: Uint16Array,
    dims: number[]
  ): GreedyMesherResult {
    return this.greedyMesher(voxelData, dims);
  }

  public load(
    scene: BABYLON.Scene,
    worldJoinData: WorldJoinData
  ): Promise<void> {
    return Promise.resolve()
      .then(() => {
        console.log(worldJoinData);
        this._sceneNode = new BABYLON.TransformNode("VoxelEngine Node", scene);
        this._worldSize = new BABYLON.Vector3(
          worldJoinData.info.size[0],
          worldJoinData.info.size[1],
          worldJoinData.info.size[2]
        );
        this.chunkCount = new BABYLON.Vector3(
          Math.ceil(this._worldSize.x / this._chunkSize.x),
          Math.ceil(this._worldSize.y / this._chunkSize.y),
          Math.ceil(this._worldSize.z / this._chunkSize.z)
        );
        this.greedyMesher = greedyMeshing(
          this._chunkSize.x * this._chunkSize.y * this._chunkSize.z
        );
      })
      .then(() => {
        // Create chunks
        console.log("Creating chunks");
        const position = BABYLON.Vector3.Zero();
        for (let cy = 0; cy < this.chunkCount.y; cy++) {
          position.y = cy;
          for (let cx = 0; cx < this.chunkCount.x; cx++) {
            position.x = cx;
            for (let cz = 0; cz < this.chunkCount.z; cz++) {
              position.z = cz;
              this.chunks[this.getChunkIndexFromChunkPosition(position)] =
                new VoxelChunk(this, position);
            }
          }
        }
        return nodegzip.ungzip(Buffer.from(worldJoinData.blocks, "base64"));
      })
      .then((buffer) => {
        // Fill chunks
        console.log("Building voxels");
        const worldBlocks = new Uint16Array(
          buffer.buffer,
          buffer.byteOffset,
          buffer.length / 2
        );
        const position = BABYLON.Vector3.Zero();
        let i = 0;
        for (let y = 0; y < this._worldSize.y; y++) {
          position.y = y;
          for (let x = 0; x < this._worldSize.x; x++) {
            position.x = x;
            for (let z = 0; z < this._worldSize.z; z++, i++) {
              position.z = z;
              const cvp = this.getChunkVoxelPositionFromWorldPosition(position);
              this.chunks[cvp.chunkIndex].setVoxel(
                cvp.voxelPosition,
                worldBlocks[i]
              );
            }
          }
        }
      })
      .then(() => {
        // Build chunks
        console.log("Building chunks");
        for (const chunk of this.chunks) {
          chunk.build();
        }
      });
  }

  public setVoxel(position: BABYLON.Vector3, voxelId: number): void {
    const roundedPosition = new BABYLON.Vector3(
      Math.floor(position.x),
      Math.floor(position.y),
      Math.floor(position.z)
    );
    if (
      roundedPosition.x < 0 ||
      roundedPosition.y < 0 ||
      roundedPosition.z < 0 ||
      roundedPosition.x >= this.worldSize.x ||
      roundedPosition.y >= this.worldSize.y ||
      roundedPosition.z >= this.worldSize.z
    )
      return;

    const cvp = this.getChunkVoxelPositionFromWorldPosition(roundedPosition);
    this.chunks[cvp.chunkIndex].setVoxel(cvp.voxelPosition, voxelId);
    this.chunks[cvp.chunkIndex].build();
  }

  public sendVoxel(position: BABYLON.Vector3, voxelId: number): void {
    this.setVoxel(position, voxelId);
    WebSocketManager.send<WorldPlace>(Endpoints.WebSocket.place, {
      position: [position.x, position.y, position.z],
      voxelId,
    });
  }

  private getChunkVoxelPositionFromWorldPosition(
    roundedPosition: BABYLON.Vector3
  ): ChunkVoxelPosition {
    const chunkIndex = this.getChunkIndexFromChunkPosition(
      new BABYLON.Vector3(
        Math.floor(roundedPosition.x / this._chunkSize.x),
        Math.floor(roundedPosition.y / this._chunkSize.y),
        Math.floor(roundedPosition.z / this._chunkSize.z)
      )
    );
    const voxelPosition = new BABYLON.Vector3(
      Math.floor(roundedPosition.x) % this._chunkSize.x,
      Math.floor(roundedPosition.y) % this._chunkSize.y,
      Math.floor(roundedPosition.z) % this._chunkSize.z
    );
    return { chunkIndex, voxelPosition };
  }

  private getChunkIndexFromChunkPosition(position: BABYLON.Vector3): number {
    return (
      position.z +
      this.chunkCount.z * (position.x + this.chunkCount.x * position.y)
    );
  }
}
