import ky, { ResponsePromise } from "ky";

import { EnvironmentVariables } from "#root/lib/environmentVariables";
import router from "#root/router";
import {
  MainAlertData,
  PostRequest,
  ShowMainAlertData,
} from "#root/lib/interfaces";

import { LocaleKeys } from "#mws/data";
import { RootMessage } from "#mws/interfaces";
import PersistentModule from "#root/store/persistent";
import AppModule from "#root/store/app";
import Store from "#root/store/store";

const INVALID_TOKEN_ALERT_DATA: ShowMainAlertData = {
  title: { key: LocaleKeys.alert.logout },
  message: { key: LocaleKeys.server.auth.error.invalidToken },
  buttons: [
    {
      text: { key: LocaleKeys.alert.logout },
      color: "primary",
      action: () => {
        router.push("/login");
      },
    },
  ],
};

export function post<Send = any, Receive = any>(
  request: PostRequest<Send>
): Promise<Receive> {
  if (!request.background) {
    AppModule.loadingAlert();
  }
  let promise: ResponsePromise;
  if (request.data || request.auth) {
    promise = ky.post(`${EnvironmentVariables.SERVER_URL}${request.endpoint}`, {
      json: Object.assign(
        {},
        request.data && { data: request.data },
        request.auth && { auth: PersistentModule.session }
      ),
    });
  } else {
    promise = ky.post(`${EnvironmentVariables.SERVER_URL}${request.endpoint}`);
  }
  return promise
    .json<RootMessage<Receive>>()
    .then((result) => {
      if (result.error) {
        return Promise.reject(result.error);
      }
      if (!request.background) {
        AppModule.hideAlert();
      }
      return Promise.resolve(result.data as Receive);
    })
    .catch((error) => {
      if (typeof error !== "string") {
        error = LocaleKeys.server.error.unreachable;
      }
      if (error === LocaleKeys.server.auth.error.invalidToken) {
        Store.logout();
        if (!request.background) {
          AppModule.showAlert(Object.assign({}, INVALID_TOKEN_ALERT_DATA));
        }
        return Promise.reject(error);
      }
      if (!request.background) {
        AppModule.showAlert({
          title: { key: LocaleKeys.alert.error },
          message: { key: error },
          buttons: [
            {
              text: { key: LocaleKeys.alert.ok },
              color: "primary",
              action: AppModule.hideAlert,
            },
          ],
        });
      }
      return Promise.reject(error);
    });
}

export function t(
  key: string,
  values: (number | string)[] = [],
  overrideLang?: string
): string {
  return PersistentModule.translate({ key, values, overrideLang });
}
