import Vue from "vue";
import VueCookies from "vue-cookies";
import VueI18n from "vue-i18n";

import App from "#root/App.vue";
import "#root/registerServiceWorker";
import router from "#root/router";
import vuetify from "#root/plugins/vuetify";
import store from "#root/store/realStore";
import Store from "./store/store";

Vue.config.productionTip = false;

// Use
Vue.use(VueCookies);
Vue.use(VueI18n);

new Vue({
  router,
  vuetify,
  store,
  beforeCreate() {
    Store.init();
  },
  render: (h) => h(App),
}).$mount("#app");
