import * as BABYLON from "babylonjs";
import { SimpleEventList } from "strongly-typed-events";

import WebSocketManager from "#root/lib/webSocketManager";
import router from "#root/router";
import VoxelEngine from "#root/game/voxelEngine";
import textureDefault from "#root/game/data/default.png";
import { getBasicMaterial } from "#root/game/data/shaders";
import Player from "#root/game/objects/player";
import MyInputManager from "#root/game/inputManager";
import PlayerMesh from "#root/game/objects/playerMesh";

import { voxelsJSON, Endpoints, LocaleKeys } from "#mws/data";
import { WorldJoinData, PlayerEntityData } from "#mws/interfaces";
import {
  WorldSpawn,
  WorldDespawn,
  WorldUpdate,
  WorldPlace,
} from "#mws/interfaces";
import AppModule from "#root/store/app";

// eslint-disable-next-line
// @ts-ignore
//import * as Ammo from "ammo.js";

interface QueuedMessage {
  action: string[];
  data: any;
}

export default class GameManager {
  private static engine: BABYLON.Engine;
  private static scene: BABYLON.Scene;
  private static _voxelEngine: VoxelEngine;
  private static _player: Player;
  private static myUpdateTimer?: NodeJS.Timer;
  private static loaded = false;
  private static queuedEvents = new Array<QueuedMessage>();
  private static _uiEvent = new SimpleEventList<void>();
  private static entities = new Map<number, BABYLON.Node>();

  public static get uiEvent(): SimpleEventList<void> {
    return GameManager._uiEvent;
  }

  public static get voxelEngine(): VoxelEngine {
    return this._voxelEngine;
  }

  public static get player(): Player {
    return GameManager._player;
  }

  public static init(): void {
    this._voxelEngine = new VoxelEngine();

    // Voxels
    Object.entries(voxelsJSON.voxels).forEach(([key, value]) => {
      this._voxelEngine.registerVoxel(
        {
          materialId: value.materialId,
          textureCoords: [value.textureCoords[0], value.textureCoords[1]],
        },
        parseInt(key)
      );
    });

    WebSocketManager.close.subscribe((reason) => {
      GameManager.queuedEvents.length = 0;
      if (GameManager.myUpdateTimer !== undefined) {
        clearTimeout(GameManager.myUpdateTimer);
      }
      if (GameManager.scene) {
        (
          GameManager.scene.getNodeByName("InputManager")! as MyInputManager
        ).exitPointerLock();
      }
      AppModule.showAlert({
        title: { key: LocaleKeys.alert.error },
        message: { key: reason },
        buttons: [
          {
            color: "primary",
            text: { key: LocaleKeys.alert.ok },
            action: () => {
              AppModule.loadingAlert();
              GameManager.dispose();
              router.push("/menu");
            },
          },
        ],
      });
    });

    WebSocketManager.message
      .get(Endpoints.WebSocket.place[0])
      .subscribe((wsm) => {
        GameManager.processMessage({
          action: ["place"],
          data: wsm.data,
        });
      });

    WebSocketManager.message
      .get(Endpoints.WebSocket.spawn[0])
      .subscribe((wsm) => {
        GameManager.processMessage({
          action: ["spawn"],
          data: wsm.data,
        });
      });

    WebSocketManager.message
      .get(Endpoints.WebSocket.update[0])
      .subscribe((wsm) => {
        GameManager.processMessage({
          action: ["update"],
          data: wsm.data,
        });
      });

    WebSocketManager.message
      .get(Endpoints.WebSocket.despawn[0])
      .subscribe((wsm) => {
        GameManager.processMessage({
          action: ["despawn"],
          data: wsm.data,
        });
      });
  }

  private static processMessage(qm: QueuedMessage): void {
    if (!GameManager.loaded) {
      GameManager.queuedEvents.push(qm);
      return;
    }

    switch (qm.action.shift()) {
      case "place": {
        const worldPlace: WorldPlace = qm.data;
        GameManager.voxelEngine.setVoxel(
          new BABYLON.Vector3(
            worldPlace.position[0],
            worldPlace.position[1],
            worldPlace.position[2]
          ),
          worldPlace.voxelId
        );
        break;
      }
      case "spawn": {
        const worldSpawn: WorldSpawn = qm.data;
        const entityData = worldSpawn.entity as PlayerEntityData;
        console.log("Spawn", worldSpawn.entity.id);
        const playerMesh = new PlayerMesh(
          worldSpawn.entity.id,
          entityData.name,
          GameManager.scene
        );
        playerMesh.update(
          new BABYLON.Vector3(
            worldSpawn.entity.position[0],
            worldSpawn.entity.position[1],
            worldSpawn.entity.position[2]
          )
        );
        GameManager.entities.set(worldSpawn.entity.id, playerMesh);
        break;
      }
      case "update": {
        const worldUpdate: WorldUpdate = qm.data;
        const playerMesh = GameManager.entities.get(
          worldUpdate.id
        )! as PlayerMesh;
        playerMesh.update(
          new BABYLON.Vector3(
            worldUpdate.data.position[0],
            worldUpdate.data.position[1],
            worldUpdate.data.position[2]
          )
        );
        break;
      }
      case "despawn": {
        const worldDespawn: WorldDespawn = qm.data;
        console.log("Despawn", worldDespawn.id);
        GameManager.entities.get(worldDespawn.id)!.dispose();
        break;
      }
    }
  }

  private static loadTexture(
    url: string,
    sceneOrEngine: BABYLON.ThinEngine | BABYLON.Scene,
    noMipmap?: boolean | undefined,
    invertY?: boolean | undefined,
    samplingMode?: number | undefined
  ): Promise<BABYLON.Texture> {
    return new Promise((resolve) => {
      const texture = new BABYLON.Texture(
        url,
        sceneOrEngine,
        noMipmap,
        invertY,
        samplingMode,
        () => {
          resolve(texture);
        }
      );
    });
  }

  public static load(
    canvas: HTMLCanvasElement | WebGLRenderingContext,
    worldJoinData: WorldJoinData
  ): Promise<void> {
    return Promise.resolve()
      .then(() => {
        console.log("Set engine & scene");
        GameManager.engine = new BABYLON.Engine(canvas, true, {
          preserveDrawingBuffer: true,
          stencil: true,
        });
        GameManager.scene = new BABYLON.Scene(GameManager.engine);
        //GameManager.scene.debugLayer.show();
        new MyInputManager(GameManager.scene);
        //return Ammo();
      })
      .then(() => {
        //GameManager.scene.enablePhysics(new BABYLON.Vector3(0, -9.81, 0), new BABYLON.AmmoJSPlugin());
      })
      .then(() => {
        return Promise.all([
          GameManager.loadTexture(
            textureDefault,
            GameManager.scene,
            true,
            false,
            BABYLON.Texture.NEAREST_NEAREST
          ),
        ]);
      })
      .then(([resultTextureDefault]) => {
        GameManager._voxelEngine.registerMaterial(
          getBasicMaterial(
            GameManager.scene,
            resultTextureDefault,
            this._voxelEngine.voxelSize
          ),
          0
        );
      })
      .then(() => {
        return GameManager._voxelEngine.load(GameManager.scene, worldJoinData);
      })
      .then(() => {
        GameManager._player = new Player(GameManager.scene);
        GameManager._player.setPosition(
          new BABYLON.Vector3(
            GameManager.voxelEngine.worldSize.x / 2.0,
            GameManager.voxelEngine.worldSize.y + 8,
            GameManager.voxelEngine.worldSize.z / 2.0
          )
        );

        GameManager.myUpdateTimer = setInterval(GameManager.updateMy, 50);

        GameManager.loaded = true;

        for (const entityData of worldJoinData.entities) {
          if (entityData.id !== worldJoinData.myId) {
            GameManager.processMessage({
              action: ["spawn"],
              data: { entity: entityData },
            });
          }
        }

        for (const qm of GameManager.queuedEvents) {
          GameManager.processMessage(qm);
        }
        GameManager.queuedEvents.length = 0;

        //GameManager.scene.debugLayer.show();

        GameManager.engine.runRenderLoop(function () {
          GameManager.scene.render();
        });

        // Watch for browser/canvas resize events
        window.addEventListener("resize", function () {
          GameManager.engine.resize();
        });
      })
      .then(() => {
        AppModule.hideAlert();
      });
  }

  private static updateMy(): void {
    WebSocketManager.send(Endpoints.WebSocket.playerUpdate, {
      position: [
        GameManager._player.getPosition().x,
        GameManager._player.getPosition().y,
        GameManager._player.getPosition().z,
      ],
    });
  }

  public static dispose(): void {
    if (GameManager.myUpdateTimer !== undefined) {
      clearTimeout(GameManager.myUpdateTimer);
    }
    GameManager.entities.clear();
    WebSocketManager.close.clear();
    WebSocketManager.closeConnection();
    MyInputManager.instance.dispose();
    GameManager.engine.stopRenderLoop();
  }
}
