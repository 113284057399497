import * as BABYLON from "babylonjs";
import * as BABYLON_GUI from "babylonjs-gui";

export default class PlayerMesh extends BABYLON.Node {
  private mesh: BABYLON.Mesh;
  private newPosition = BABYLON.Vector3.Zero();

  constructor(id: number, name: string, scene: BABYLON.Scene) {
    super(`PlayerMesh ${id}`, scene);

    this.mesh = BABYLON.CapsuleBuilder.CreateCapsule(
      `PlayerMesh ${id} Capsule`,
      {
        height: 1.8,
        radius: 0.4,
        subdivisions: 0,
        tessellation: 32,
        capSubdivisions: 16,
      },
      this.getScene()
    );
    this.mesh.isPickable = false;

    const plane = BABYLON.PlaneBuilder.CreatePlane("plane", {
      size: 2,
    });
    plane.isPickable = false;
    plane.parent = this.mesh;
    plane.position.y = 1.25;
    plane.billboardMode = BABYLON.Mesh.BILLBOARDMODE_Y;

    const advancedTexture =
      BABYLON_GUI.AdvancedDynamicTexture.CreateForMesh(plane);

    const button1 = BABYLON_GUI.Button.CreateSimpleButton("but1", name);
    button1.width = 1;
    button1.height = 0.2;
    button1.color = "white";
    button1.thickness = 0;
    button1.fontSize = 100;
    button1.background = "transparent";
    advancedTexture.addControl(button1);

    this.getScene().registerBeforeRender(() => {
      this.lerp();
    });
  }

  private lerp(): void {
    this.mesh.position = BABYLON.Vector3.Lerp(
      this.mesh.position,
      this.newPosition,
      1.0 -
        Math.pow(0.00001, this.getScene().getEngine().getDeltaTime() / 1000.0)
    );
  }

  public update(position: BABYLON.Vector3): void {
    this.newPosition = position;
  }
}
