import PersistentStore from "#root/store/persistent";
import PersistentModule from "./persistent";
import SessionModule from "./session";

export default class Store {
  public static init(): void {
    PersistentStore.init();
  }

  public static logout(): void {
    PersistentModule.clear();
    SessionModule.clear();
  }
}
