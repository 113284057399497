import {
  MainAlertButton,
  MainAlertData,
  ShowMainAlertData,
} from "#root/lib/interfaces";
import store from "#root/store/realStore/index";
import assert from "assert";
import Vue from "vue";

export default class AppModule {
  public static delayedHide?: NodeJS.Timeout;

  public static get alert(): MainAlertData {
    return store.getters["app/alert"];
  }

  private static setAlert(alertData: MainAlertData): void {
    store.commit("app/alert", alertData);
  }

  public static setAlertButton(button: MainAlertButton, index: number): void {
    const alert = AppModule.alert;
    assert(alert.buttons !== undefined);

    Vue.set(alert.buttons, index, button);
    AppModule.setAlert(alert);
  }

  public static showAlert(alertData: ShowMainAlertData): void {
    assert(alertData.title !== undefined);
    assert(alertData.message !== undefined);
    assert(alertData.buttons !== undefined);

    AppModule.setAlert({
      shown: true,
      loading: false,
      title: alertData.title,
      message: alertData.message,
      buttons: alertData.buttons,
      persistent: alertData.persistent || true,
    });
  }

  public static hideAlert(): void {
    AppModule.delayedHide = setTimeout(() => {
      AppModule.setAlert({ shown: false, loading: false });
    }, 0);
  }

  public static loadingAlert(): void {
    if (AppModule.delayedHide !== undefined) {
      clearTimeout(AppModule.delayedHide);
      AppModule.delayedHide = undefined;
    }
    AppModule.setAlert({ shown: true, loading: true });
  }
}
