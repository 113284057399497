import * as BABYLON from "babylonjs";
import MyInputManager from "#root/game/inputManager";
import GameManager from "../game";

const CAMERA_DISPLACEMENT = new BABYLON.Vector3(0, 0.7, 0);
const CAMERA_FOV = 1.309;
const CAMERA_MIN_Z = 0.1;
const CAMERA_MAX_Z = 256.0;
const CAMERA_SENS = 0.001;
const GRAVITY_FORCE = 36;
const WALK_FORCE = 6;
const JUMPFORCE = 9;
const CAPSULE_SIZE = new BABYLON.Vector2(0.4, 1.8);
const MAX_GRAVITY = 50.0;
const RAY_MARGIN = 0.05;
const RAY_LENGTH = 0.05;
const POINTER_WIDTH = 2.0;

export default class Player extends BABYLON.Node {
    private camera: BABYLON.Camera;
    private cameraRoot: BABYLON.TransformNode;
    private gravity = 0;
    private cameraRotation = 0;
    private holdingVoxel = 1;

    private onGround: BABYLON.Nullable<BABYLON.PickingInfo> = null;
    private onCeiling: BABYLON.Nullable<BABYLON.PickingInfo> = null;

    private visualMesh: BABYLON.Mesh;
    private holdingMesh: BABYLON.Mesh;
    private pointerBox: BABYLON.Mesh;
    private inputManager: MyInputManager;

    constructor(scene: BABYLON.Scene) {
        super("Player", scene);

        this.visualMesh = this.setupPlayerMesh();
        this.pointerBox = this.setupPointerBox();
        const cameraNodes = this.setupPlayerCamera();
        this.camera = cameraNodes.camera;
        this.cameraRoot = cameraNodes.root;
        this.holdingMesh = this.setupHoldingMesh();
        this.inputManager = scene.getNodeByName("InputManager") as MyInputManager;

        scene.registerBeforeRender(() => {
            this.checkPlayer();
            this.beforeRenderUpdate();
            this.updateCamera();
        });
    }

    public setPosition(position: BABYLON.Vector3): void {
        this.visualMesh.position = position;
    }

    public getPosition(): BABYLON.Vector3 {
        return this.visualMesh.position;
    }

    private setupPlayerCamera() {
        const root = new BABYLON.TransformNode(
            `${this.name} CameraRoot`,
            this.getScene()
        );
        root.parent = this.visualMesh;
        root.position = CAMERA_DISPLACEMENT;

        const camera = new BABYLON.UniversalCamera(
            `${this.name} Camera`,
            BABYLON.Vector3.Zero(),
            this.getScene()
        );
        camera.minZ = CAMERA_MIN_Z;
        camera.maxZ = CAMERA_MAX_Z;
        camera.fov = CAMERA_FOV;
        camera.parent = root;
        camera.checkCollisions = false;

        const pipeline = new BABYLON.DefaultRenderingPipeline(
            "defaultPipeline",
            false,
            this.getScene(),
            [camera]
        );

        pipeline.samples = 4;

        return { camera, root };
    }

    private setupPlayerMesh(): BABYLON.Mesh {
        const mesh = BABYLON.CapsuleBuilder.CreateCapsule(
            `${this.name} Mesh`,
            {
                height: CAPSULE_SIZE.y,
                radius: CAPSULE_SIZE.x,
                subdivisions: 0,
                tessellation: 32,
                capSubdivisions: 16,
            },
            this.getScene()
        );
        // mesh.physicsImpostor = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.MeshImpostor, {
        //     mass: 1,
        //     restitution: 0,
        //     friction: 0,
        //     ignoreParent: true
        // }, this.getScene());
        mesh.visibility = 0;
        mesh.ellipsoid = mesh.getBoundingInfo().boundingBox.extendSize;
        mesh.isPickable = false;
        //mesh.ellipsoid = new BABYLON.Vector3(CAPSULE_SIZE.x, CAPSULE_SIZE.y, CAPSULE_SIZE.x);
        //mesh.ellipsoidOffset = new BABYLON.Vector3(0, CAPSULE_SIZE.y / 2.0, 0);

        mesh.parent = this;
        return mesh;
    }

    private setupHoldingMesh(): BABYLON.Mesh {
        const faceUV = new Array(6);
        const voxelFaces = GameManager.voxelEngine.voxelDefinitions[this.holdingVoxel].textureCoords;

        for (let i = 0; i < 6; i++) {
            faceUV[i] = new BABYLON.Vector4(voxelFaces[0], voxelFaces[1], voxelFaces[0], voxelFaces[1]);
        }

        const box = BABYLON.MeshBuilder.CreateBox(`${this.name} HoldingMesh`, {
            size: 1.0,
            faceUV: faceUV,
            updatable: true
        }, this.getScene());
        box.isPickable = false;
        box.material = GameManager.voxelEngine.materials[GameManager.voxelEngine.voxelDefinitions[this.holdingVoxel].materialId];
        box.renderingGroupId = 3;
        box.parent = this.cameraRoot;
        box.position = new BABYLON.Vector3(1.5, -2.0, 2.5);
        const vertices = box.getVerticesData(BABYLON.VertexBuffer.PositionKind)!;
        for (let i = 0; i < vertices.length; i++) {
            vertices[i] += 0.5;
        }
        box.setVerticesData(BABYLON.VertexBuffer.PositionKind, vertices);
        return box;
    }

    public updateHoldingMesh(voxelId: number): void {
        this.holdingMesh.dispose();
        this.holdingVoxel = voxelId;
        this.holdingMesh = this.setupHoldingMesh();
    }

    private setupPointerBox(): BABYLON.Mesh {
        const pointerBox = BABYLON.BoxBuilder.CreateBox(
            "PointerBox",
            { size: 1.01 },
            this.getScene()
        );
        pointerBox.enableEdgesRendering(0.1);
        pointerBox.edgesWidth = POINTER_WIDTH;
        pointerBox.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
        pointerBox.material = new BABYLON.StandardMaterial(
            "PointerBoxMaterial",
            this.getScene()
        );
        pointerBox.material.alpha = 0;
        pointerBox.isPickable = false;
        return pointerBox;
    }

    private checkPlayer(): void {
        this.onGround = null;
        if (this.gravity >= 0) {
            const rayGround = new BABYLON.Ray(
                this.visualMesh.position.add(
                    new BABYLON.Vector3(0, -this.visualMesh.ellipsoid.y + RAY_MARGIN, 0)
                ),
                BABYLON.Vector3.Down(),
                RAY_LENGTH + RAY_MARGIN
            );
            const hitGround = this.getScene().pickWithRay(rayGround);
            if (hitGround?.hit) {
                this.onGround = hitGround;
            } else {
                const trials = 12.0;
                const margin = -0.025;
                for (let i = 0; i < trials; i++) {
                    const angle = (i / trials) * Math.PI * 2.0;
                    const angledRay = new BABYLON.Ray(
                        this.visualMesh.position.add(
                            new BABYLON.Vector3(
                                Math.cos(angle) * (margin + this.visualMesh.ellipsoid.x),
                                -this.visualMesh.ellipsoid.y + RAY_MARGIN,
                                Math.sin(angle) * (margin + this.visualMesh.ellipsoid.z)
                            )
                        ),
                        BABYLON.Vector3.Down(),
                        RAY_LENGTH + RAY_MARGIN
                    );
                    const angledHit = this.getScene().pickWithRay(angledRay);
                    if (angledHit?.hit) {
                        this.onGround = angledHit;
                        continue;
                    }
                }
            }
        }

        const rayCeiling = new BABYLON.Ray(
            this.visualMesh.position.add(
                new BABYLON.Vector3(0, this.visualMesh.ellipsoid.y, 0)
            ),
            BABYLON.Vector3.Up(),
            RAY_LENGTH
        );
        const hitCeiling = this.getScene().pickWithRay(rayCeiling);
        if (hitCeiling?.hit) {
            this.onCeiling = hitCeiling;
        } else {
            this.onCeiling = null;
        }
    }

    private tryPlaceVoxel(position: BABYLON.Vector3, voxelId: number): void {
        if (voxelId === 0) {
            GameManager.voxelEngine.sendVoxel(position, 0);
            return;
        }
        const voxelPosition = new BABYLON.Vector3(
            Math.floor(position.x),
            Math.floor(position.y),
            Math.floor(position.z)
        );
        const mySize = new BABYLON.Vector3(
            CAPSULE_SIZE.x,
            CAPSULE_SIZE.y * 0.5,
            CAPSULE_SIZE.x
        );
        const myBoundingBox = new BABYLON.BoundingBox(
            this.visualMesh.position.subtract(mySize),
            this.visualMesh.position.add(mySize)
        );
        const voxelBoundingBox = new BABYLON.BoundingBox(
            voxelPosition,
            voxelPosition.add(BABYLON.Vector3.One())
        );
        voxelBoundingBox.scale(0.95);
        if (
            myBoundingBox.intersectsMinMax(
                voxelBoundingBox.minimum,
                voxelBoundingBox.maximum
            )
        )
            return;

        GameManager.voxelEngine.sendVoxel(position, voxelId);
    }

    private beforeRenderUpdate(): void {
        const dt = this.getEngine().getDeltaTime() / 1000.0;
        this.gravity = Math.min(this.gravity + GRAVITY_FORCE * dt, MAX_GRAVITY);

        if (this.onGround) {
            if (this.gravity >= 0) {
                if (this.inputManager.input.playerJump) {
                    this.gravity = -JUMPFORCE;
                } else {
                    this.visualMesh.position = new BABYLON.Vector3(
                        this.visualMesh.position.x,
                        this.onGround.pickedPoint!.y + this.visualMesh.ellipsoid.y,
                        this.visualMesh.position.z
                    );
                    this.gravity = 0;
                }
            }
        }

        if (this.onCeiling && this.gravity < 0) {
            this.gravity = 0;
        }

        // Input
        const matrix = BABYLON.Matrix.Identity();
        BABYLON.Matrix.FromQuaternionToRef(this.camera.absoluteRotation, matrix);
        const ray = new BABYLON.Ray(
            this.camera.globalPosition,
            BABYLON.Vector3.TransformNormal(BABYLON.Vector3.Forward(), matrix),
            8
        );
        let hit = this.getScene().pickWithRay(ray);
        if (hit?.hit) {
            let hitPosition = hit.pickedPoint!.subtract(hit.getNormal()!.scale(0.5));
            hitPosition.x = Math.floor(hitPosition.x) + 0.5;
            hitPosition.y = Math.floor(hitPosition.y) + 0.5;
            hitPosition.z = Math.floor(hitPosition.z) + 0.5;
            this.pointerBox.visibility = 1;
            this.pointerBox.position = hitPosition;
            if (this.inputManager.input.mouseLocked) {
                if (this.inputManager.input.destroy) {
                    const position = hit.pickedPoint!.subtract(
                        hit.getNormal()!.scale(0.5)
                    );
                    this.tryPlaceVoxel(position, 0);
                } else if (this.inputManager.input.place) {
                    const matrix = BABYLON.Matrix.Identity();
                    BABYLON.Matrix.FromQuaternionToRef(
                        this.camera.absoluteRotation,
                        matrix
                    );
                    const ray = new BABYLON.Ray(
                        this.camera.globalPosition,
                        BABYLON.Vector3.TransformNormal(BABYLON.Vector3.Forward(), matrix),
                        8
                    );
                    const hit = this.getScene().pickWithRay(ray);
                    if (hit?.hit) {
                        const position = hit.pickedPoint!.add(hit.getNormal()!.scale(0.5));
                        this.tryPlaceVoxel(position, this.holdingVoxel);
                    }
                }
            }
            hit = this.getScene().pickWithRay(ray);
            if (hit?.hit) {
                hitPosition = hit.pickedPoint!.subtract(hit.getNormal()!.scale(0.5));
                hitPosition.x = Math.floor(hitPosition.x) + 0.5;
                hitPosition.y = Math.floor(hitPosition.y) + 0.5;
                hitPosition.z = Math.floor(hitPosition.z) + 0.5;
                this.pointerBox.visibility = 1;
                this.pointerBox.position = hitPosition;
            } else {
                this.pointerBox.visibility = 0;
            }
        } else {
            this.pointerBox.visibility = 0;
        }
        if (this.inputManager.input.mouseLocked) {
            const forward = this.visualMesh.forward
                .scale(this.inputManager.input.playerMovement.y)
                .addInPlace(
                    this.visualMesh.right.scale(this.inputManager.input.playerMovement.x)
                );
            this.visualMesh.rotate(
                BABYLON.Vector3.UpReadOnly,
                this.inputManager.input.mouseMovement.x * CAMERA_SENS,
                BABYLON.Space.WORLD
            );
            forward.normalize();
            this.visualMesh.moveWithCollisions(forward.scale(WALK_FORCE * dt));
        }

        // Physics
        this.visualMesh.moveWithCollisions(
            BABYLON.Vector3.Down().scale(this.gravity * dt)
        );
        if (this.visualMesh.position.y < -16) {
            this.visualMesh.position = new BABYLON.Vector3(
                BABYLON.Scalar.Clamp(
                    this.visualMesh.position.x,
                    0.5,
                    GameManager.voxelEngine.worldSize.x - 0.5
                ),
                GameManager.voxelEngine.worldSize.y + 8,
                BABYLON.Scalar.Clamp(
                    this.visualMesh.position.z,
                    0.5,
                    GameManager.voxelEngine.worldSize.z - 0.5
                )
            );
            this.gravity = 0;
        }
    }

    private updateCamera(): void {
        if (this.inputManager.input.mouseLocked) {
            this.cameraRotation +=
                this.inputManager.input.mouseMovement.y * CAMERA_SENS;
            this.cameraRotation = BABYLON.Scalar.Clamp(
                this.cameraRotation,
                -Math.PI / 2.0,
                Math.PI / 2.0
            );
            this.cameraRoot.rotation.x = this.cameraRotation;
        }
    }
}
