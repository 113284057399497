export interface GreedyMesherResult {
  vertices: number[][];
  faces: number[][];
}

export default function (
  maxVoxels: number
): (voxelData: Uint16Array, dims: number[]) => GreedyMesherResult {
  const mask = new Int32Array(maxVoxels);

  return function (voxelData: Uint16Array, dims: number[]): GreedyMesherResult {
    function f(i: number, j: number, k: number): number {
      return voxelData[k + dims[2] * (i + dims[0] * j)];
    }

    //Sweep over 3-axes
    const vertices = new Array<number[]>(),
      faces = new Array<number[]>();
    for (let d = 0; d < 3; ++d) {
      let i: number;
      let j: number;
      let k: number;
      let l: number;
      let w: number;
      let h: number;
      const u = (d + 1) % 3;
      const v = (d + 2) % 3;
      const x = [0, 0, 0];
      const q = [0, 0, 0];
      q[d] = 1;

      for (x[d] = -1; x[d] < dims[d]; ) {
        //Compute mask
        let n = 0;
        for (x[v] = 0; x[v] < dims[v]; ++x[v]) {
          for (x[u] = 0; x[u] < dims[u]; ++x[u], ++n) {
            const a = 0 <= x[d] ? f(x[0], x[1], x[2]) : 0;
            const b =
              x[d] < dims[d] - 1 ? f(x[0] + q[0], x[1] + q[1], x[2] + q[2]) : 0;
            if (!!a === !!b) {
              mask[n] = 0;
            } else if (a) {
              mask[n] = a;
            } else {
              mask[n] = -b;
            }
          }
        }
        //Increment x[d]
        ++x[d];
        //Generate mesh for mask using lexicographic ordering
        n = 0;
        for (j = 0; j < dims[v]; ++j) {
          for (i = 0; i < dims[u]; ) {
            let c = mask[n];
            if (c) {
              //Compute width
              for (w = 1; mask[n + w] === c && i + w < dims[u]; ++w);
              //Compute height (this is slightly awkward
              let done = false;
              for (h = 1; j + h < dims[v]; ++h) {
                for (k = 0; k < w; ++k) {
                  if (c !== mask[n + k + h * dims[u]]) {
                    done = true;
                    break;
                  }
                }
                if (done) {
                  break;
                }
              }
              //Add quad
              x[u] = i;
              x[v] = j;
              const du = [0, 0, 0],
                dv = [0, 0, 0];
              if (c > 0) {
                dv[v] = h;
                du[u] = w;
              } else {
                c = -c;
                du[v] = h;
                dv[u] = w;
              }
              const vertexCount = vertices.length;
              vertices.push([x[0], x[1], x[2]]);
              vertices.push([x[0] + du[0], x[1] + du[1], x[2] + du[2]]);
              vertices.push([
                x[0] + du[0] + dv[0],
                x[1] + du[1] + dv[1],
                x[2] + du[2] + dv[2],
              ]);
              vertices.push([x[0] + dv[0], x[1] + dv[1], x[2] + dv[2]]);
              faces.push([
                vertexCount,
                vertexCount + 1,
                vertexCount + 2,
                vertexCount + 3,
                c,
              ]);

              //Zero-out mask
              for (l = 0; l < h; ++l) {
                for (k = 0; k < w; ++k) {
                  mask[n + k + l * dims[u]] = 0;
                }
              }
              //Increment counters and continue
              i += w;
              n += w;
            } else {
              ++i;
              ++n;
            }
          }
        }
      }
    }

    return { vertices, faces };
  };
}
