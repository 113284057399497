export const EnvironmentVariables = {
  SERVER_URL:
    process.env.NODE_ENV === "production"
      ? "https://mini-worlds.herokuapp.com"
      : "http://localhost",
  WEBSOCKET_URL:
    process.env.NODE_ENV === "production"
      ? "wss://mini-worlds.herokuapp.com"
      : "ws://localhost",
};
