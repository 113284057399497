import * as BABYLON from "babylonjs";

export function getBasicMaterial(
  scene: BABYLON.Scene,
  texture: BABYLON.Texture,
  voxelSize: BABYLON.Vector2
): BABYLON.Material {
  BABYLON.Effect.ShadersStore["customVertexShader"] = `
    precision highp float;

    attribute vec3 position;
    attribute vec3 normal;
    attribute vec2 uv;

    uniform mat4 worldViewProjection;

    varying vec3 v_position;
    varying vec3 v_normal;
    varying vec2 v_uv;

    vec2 u_tileSize = vec2(16, 16);
    vec2 u_texelSize = vec2(0.00390625, 0.00390625);

    void main(void) {
        gl_Position = worldViewProjection * vec4(position, 1.0);

        v_position = position;
        v_normal = normal;
        v_uv = uv;
    }`;
  BABYLON.Effect.ShadersStore["customFragmentShader"] = `
    precision highp float;

    varying vec3 v_position;
    varying vec3 v_normal;
    varying vec2 v_uv;

    uniform vec2 voxelSize;
    uniform vec2 texelSize;
    uniform sampler2D diffuseTexture;

    uniform vec3 ambientColor;
    uniform vec3 directionalLight;
    uniform vec3 directionalLightColor;

    void main(void) {
        vec2 tileUV = vec2(dot(v_normal.zxy, v_position), dot(v_normal.yzx, v_position));
        vec2 texCoord = voxelSize * (v_uv + fract(tileUV));

        vec3 diffuseColor = max(dot(v_normal, -directionalLight), 0.0) * directionalLightColor;
        vec3 lightColor = clamp(ambientColor + diffuseColor, 0.0, 1.0);
        gl_FragColor = vec4(texture(diffuseTexture, texCoord * texelSize).rgb * lightColor, 1.0);
    }`;

  const nodeMaterial = new BABYLON.ShaderMaterial(
    "BasicMaterial",
    scene,
    {
      vertex: "custom",
      fragment: "custom",
    },
    {
      attributes: ["position", "normal", "uv"],
      uniforms: ["worldViewProjection", "world"],
    }
  );
  nodeMaterial.setTexture("diffuseTexture", texture);
  nodeMaterial.setVector2("voxelSize", voxelSize);
  nodeMaterial.setVector2(
    "texelSize",
    new BABYLON.Vector2(
      1.0 / texture.getSize().width,
      1.0 / texture.getSize().height
    )
  );

  nodeMaterial.setVector3("ambientColor", new BABYLON.Vector3(0.5, 0.5, 0.5));
  nodeMaterial.setVector3(
    "directionalLight",
    new BABYLON.Vector3(-0.5, -1.0, -2.0).normalize()
  );
  nodeMaterial.setVector3(
    "directionalLightColor",
    new BABYLON.Vector3(1.0, 1.0, 1.0)
  );

  return nodeMaterial;
}
