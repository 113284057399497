/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  SimpleEventList,
  SignalDispatcher,
  SimpleEventDispatcher,
} from "strongly-typed-events";
import { LocaleKeys } from "#mws/data";

export interface WebSocketMessage {
  action?: string[];
  reply?: number;
  data?: any;
}

export default class WebSocketManager {
  private static ws: WebSocket;
  private static replyIndex = 0;
  private static _open = new SignalDispatcher();
  private static _message = new SimpleEventList<WebSocketMessage>();
  private static _close = new SimpleEventDispatcher<string>();

  public static get open(): SignalDispatcher {
    return WebSocketManager._open;
  }
  public static get message(): SimpleEventList<WebSocketMessage> {
    return WebSocketManager._message;
  }
  public static get close(): SimpleEventDispatcher<string> {
    return WebSocketManager._close;
  }

  public static connect(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      WebSocketManager.ws = new WebSocket(url);

      WebSocketManager.ws.onopen = () => {
        resolve();
        WebSocketManager._open.dispatch();
      };

      WebSocketManager.ws.onmessage = (ev) => {
        if (ev.data === "ping") {
          WebSocketManager.ws.send("pong");
          return;
        }

        const data = JSON.parse(ev.data);
        if (data.reply !== undefined) {
          WebSocketManager._message
            .get(`_reply_${data.reply}`)
            .dispatch(data.data);
        } else {
          const action: string = data.action.shift();
          WebSocketManager._message.get(action).dispatch(data);
        }
      };

      WebSocketManager.ws.onclose = (ev) => {
        try {
          const data = JSON.parse(ev.reason);
          if (data.error === undefined) {
            reject(LocaleKeys.server.error.unreachable);
            WebSocketManager._close.dispatch(
              LocaleKeys.server.error.unreachable
            );
          } else {
            reject(data.error);
            WebSocketManager._close.dispatch(data.error);
          }
        } catch {
          reject(LocaleKeys.server.error.unreachable);
          WebSocketManager._close.dispatch(LocaleKeys.server.error.unreachable);
        }
      };
    });
  }

  public static closeConnection(): void {
    if (
      WebSocketManager.ws.readyState !== WebSocket.CLOSED &&
      WebSocketManager.ws.readyState !== WebSocket.CLOSING
    ) {
      WebSocketManager.ws.close();
    }
  }

  public static send<T>(action: string[], data: T): void {
    WebSocketManager.ws.send(JSON.stringify({ action, data }));
  }

  public static sendRequest(action: string[], data: any): Promise<any> {
    const currentReply = WebSocketManager.replyIndex;
    WebSocketManager.replyIndex++;
    WebSocketManager.ws.send(
      JSON.stringify({ action, data, reply: currentReply })
    );
    return new Promise((resolve, reject) => {
      WebSocketManager._close.subscribe((reason) => {
        reject(reason);
      });
      function preResolve(data: any) {
        WebSocketManager._message
          .get(`_reply_${currentReply}`)
          .unsubscribe(preResolve);
        if ("error" in data) {
          reject(data.error);
        } else {
          resolve(data);
        }
      }
      WebSocketManager._message
        .get(`_reply_${currentReply}`)
        .subscribe(preResolve);
    });
  }
}
