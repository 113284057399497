export const localeTable = {
  "en-US": {
    lang: {
      id: "en-US",
      flag: "us",
      description: "English (US)",
    },
    alert: {
      retry: "Retry",
      error: "Error",
      loggedOut: "Logged out",
      ok: "Ok",
      cancel: "Cancel",
      logout: "Logout",
      close: "Close",
    },
    server: {
      error: {
        unreachable: "Could not reach server",
        database: "Server could not process request",
        messageFormat: "Unknown request format",
      },
      auth: {
        error: {
          invalidToken: "Invalid session token",
        },
        email: {
          error: {
            taken: "Email is already taken",
            credentials: "Invalid credentials",
            validationTimeout:
              "Confirmation email already sent; if you have not received it wait at least a minute before registering again",
          },
          validationNeeded: "Check your email to confirm account registration",
        },
      },
      profile: {
        error: {
          usernameTaken: "Username is already taken",
        },
      },
      world: {
        error: {
          creationLimit: "World limit reached",
          load: "World could not load",
          deleted: "World has been deleted",
        },
      },
    },
    fieldError: {
      minCharacters: "Field must have at least {0} characters",
      maxCharacters: "Field cannot have more than {0} characters",
      emailInvalid: "Email format is invalid",
      passwordMismatch: "Password must match",
      usernameInvalid: "Name contains invalid characters",
      empty: "Field cannot be empty",
    },
    loginForm: {
      tabs: {
        login: "Login",
        register: "Register",
      },
      fields: {
        email: "Email",
        password: "Password",
        repeatPassword: "Repeat password",
        signUp: "Register",
        login: "Login",
        remember: "Remember me for {0} days",
      },
    },
    userProfileForm: {
      headers: {
        setup: "Setup profile",
      },
      fields: {
        name: "Name",
        setup: "Setup",
      },
    },
    worldPanel: {
      headers: {
        worlds: "Worlds",
      },
      tabs: {
        mine: "My worlds",
        online: "Online worlds",
        new: "Latest worlds",
      },
      fields: {
        create: "Create world",
      },
    },
    worldItem: {
      size: "Size: {0}",
      owner: "by {0}",
    },
    worldForm: {
      fields: {
        name: "Name",
        width: "Width",
        height: "Height",
        depth: "Depth",
        create: "Create",
      },
      error: {
        sizeExceeded: "World size exceeded",
      },
    },
    worldDeleteAlert: {
      title: "Delete world",
      message: "Are you sure you want to delete {0}?",
      delete: "Delete",
    },
    game: {
      blocksMenu: {
        title: "Blocks"
      },
      menu: {
        title: "Game menu",
        playing: "Playing {0} by {1}",
        return: "Return to main menu",
      },
      controls: {
        move: "Move",
        look: "Look",
        place: "Place",
        break: "Break",
        jump: "Jump",
        menu: "Menu",
        blockMenu: "Block Menu",
      },
    },
  },

  "es-ES": {
    lang: {
      id: "en-ES",
      flag: "es",
      description: "Español (España)",
    },
    alert: {
      retry: "Reintentar",
      error: "Error",
      loggedOut: "Desconectado",
      ok: "Vale",
      cancel: "Cancelar",
      logout: "Desconectarse",
      close: "Cerrar",
    },
    server: {
      error: {
        unreachable: "No se pudo conectar con el servidor",
        database: "El servidor no pudo procesar la petición",
        messageFormat: "Formato de petición desconocido",
      },
      auth: {
        error: {
          invalidToken: "Token de sesión inválido",
        },
        email: {
          error: {
            taken: "El correo ya está en uso",
            credentials: "Credenciales inválidos",
            validationTimeout:
              "Email de confirmación ya enviado; si no lo has recibido espera 5 minutos antes de volver a registrarte",
          },
          validationNeeded: "Comprueba tu correo para finalizar el registro",
        },
      },
      profile: {
        error: {
          usernameTaken: "El nombre de usuario ya está en uso",
        },
      },
      world: {
        error: {
          creationLimit: "Límite de mundos alcanzado",
          load: "El mundo no se pudo cargar",
          deleted: "El mundo ha sido eliminado",
        },
      },
    },
    fieldError: {
      minCharacters: "El campo no puede tener menos de {0} caracteres",
      maxCharacters: "El campo no puede tener más de {0} caracteres",
      emailInvalid: "El formato de correo es inválido",
      passwordMismatch: "Las contraseñas deben coincidir",
      usernameInvalid: "El nombre contiene caracteres inválidos",
      empty: "El campo no puede estar vacío",
    },
    loginForm: {
      tabs: {
        login: "Entrar",
        register: "Registrarse",
      },
      fields: {
        email: "Correo",
        password: "Contraseña",
        repeatPassword: "Repetir contraseña",
        signUp: "Registrarse",
        login: "Entrar",
        remember: "Recuérdame durante {0} días",
      },
    },
    userProfileForm: {
      headers: {
        setup: "Configurar perfil",
      },
      fields: {
        name: "Nombre",
        setup: "Configurar",
      },
    },
    worldPanel: {
      headers: {
        worlds: "Mundos",
      },
      tabs: {
        mine: "Mis mundos",
        online: "Mundos online",
        new: "Últimos mundos",
      },
      fields: {
        create: "Crear mundo",
      },
    },
    worldItem: {
      size: "Tamaño: {0}",
      owner: "de {0}",
    },
    worldForm: {
      fields: {
        name: "Nombre",
        width: "Ancho",
        height: "Alto",
        depth: "Profundo",
        create: "Crear",
      },
      error: {
        sizeExceeded: "Tamaño de mundo excedido",
      },
    },
    worldDeleteAlert: {
      title: "Eliminar mundo",
      message: "Estas seguro que quieres eliminar {0}?",
      delete: "Eliminar",
    },
    game: {
      blocksMenu: {
        title: "Bloques"
      },
      menu: {
        title: "Menú del juego",
        playing: "Jugando a {0} de {1}",
        return: "Volver al menú principal",
      },
      controls: {
        move: "Mover",
        look: "Mirar",
        place: "Colocar",
        break: "Romper",
        jump: "Saltar",
        menu: "Menú",
        blockMenu: "Menú de bloques",
      },
    },
  },
};

export default localeTable;
