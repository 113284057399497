import { Module } from "vuex";
import { WorldInfo } from "#mws/interfaces";

export const sessionModule: Module<any, unknown> = {
  namespaced: true,
  state: () => ({
    profile: undefined,
    joinedWorld: undefined,
  }),
  getters: {
    profile(state: any) {
      return state.profile;
    },
    joinedWorld(state: any) {
      return state.joinedWorld;
    },
  },
  mutations: {
    profile(state: any, profile): void {
      state.profile = profile;
    },
    addUserWorld(state: any, world): void {
      const worlds = state.profile.worlds;
      worlds.push(world);
      state.profile = Object.assign({}, state.profile, { worlds });
    },
    joinedWorld(state: any, world: WorldInfo): void {
      state.joinedWorld = world;
    },
    clear(state: any): void {
      state.profile = undefined;
      state.joinedWorld = undefined;
    },
  },
};
