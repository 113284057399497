import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
//import Home from "../views/Home.vue";
//import Menu from "../views/Menu.vue";

const Login = () => import("#root/views/Login.vue");
const Menu = () => import("#root/views/Menu.vue");
const Game = () => import("#root/views/Game.vue");

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/menu",
    name: "Menu",
    component: Menu,
  },
  {
    path: "/game",
    name: "Game",
    component: Game,
  },
];

const router = new VueRouter({
  mode: "abstract",
  routes,
});

export default router;
