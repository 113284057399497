import store from "#root/store/realStore/index";
import assert from "assert";
import { UserProfile, WorldJoinData, WorldInfo } from "#mws/interfaces";

export default class SessionModule {
  public static get profile(): UserProfile | undefined {
    return store.getters["session/profile"];
  }
  public static set profile(value: UserProfile | undefined) {
    assert(value !== undefined);

    store.commit("session/profile", value);
  }

  public static get joinedWorld(): WorldJoinData | undefined {
    return store.getters["session/joinedWorld"];
  }
  public static set joinedWorld(value: WorldJoinData | undefined) {
    assert(value !== undefined);

    store.commit("session/joinedWorld", value);
  }

  public static addUserWorld(world: WorldInfo): void {
    assert(SessionModule.profile !== undefined);

    store.commit("session/addUserWorld", world);
  }

  public static clear(): void {
    store.commit("session/clear");
  }
}
