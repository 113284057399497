import * as BABYLON from "babylonjs";
import GameManager from "./game";

export default class MyInputManager extends BABYLON.TransformNode {
    public static _instance: MyInputManager | null;

    public static get instance(): MyInputManager {
        return this._instance!;
    }

    private inputMap = new Map<string, boolean>();

    private canvas: HTMLCanvasElement;

    private mouseData = {
        movement: BABYLON.Vector2.Zero(),
    };

    public input = {
        mouseLocked: false,
        playerMovement: BABYLON.Vector2.Zero(),
        playerJump: false,
        destroy: false,
        place: false,
        mouseMovement: BABYLON.Vector2.Zero(),
    };

    public constructor(scene: BABYLON.Scene) {
        super("InputManager", scene);

        MyInputManager._instance = this;

        this.canvas = scene.getEngine().getRenderingCanvas()!;

        scene.onBeforeRenderObservable.add(() => {
            this.updateFromKeyboard();
            this.updateFromMouse();

            for (const [key, justPressed] of this.inputMap) {
                if (justPressed) {
                    this.inputMap.set(key, false);
                }
            }
        });

        document.addEventListener("keydown", this.onKeyDown);
        document.addEventListener("keyup", this.onKeyUp);
        this.canvas.addEventListener("mousemove", this.onMouseMove);
        this.canvas.addEventListener("pointerup", this.onMouseUp);
        this.canvas.addEventListener("pointerdown", this.onMouseDown);
        document.addEventListener("pointerlockchange", this.onMouseLockChange);
    }

    private isJustPressed(key: string): boolean {
        const value = MyInputManager.instance.inputMap.get(key);
        return value === undefined ? false : value;
    }

    private onKeyDown(e: KeyboardEvent): void {
        MyInputManager.instance.inputMap.set(e.key, true);
    }

    private onKeyUp(e: KeyboardEvent): void {
        MyInputManager.instance.inputMap.delete(e.key);
    }

    private onMouseMove(e: MouseEvent): void {
        MyInputManager.instance.mouseData.movement.x += e.movementX;
        MyInputManager.instance.mouseData.movement.y += e.movementY;
    }

    private onMouseDown(e: PointerEvent): void {
        if (e.button === 0) {
            MyInputManager.instance.inputMap.set("mouseLeft", true);
        } else if (e.button === 2) {
            MyInputManager.instance.inputMap.set("mouseRight", true);
        }
    }

    private onMouseUp(e: PointerEvent): void {
        if (e.button === 0) {
            MyInputManager.instance.inputMap.delete("mouseLeft");
        } else if (e.button === 2) {
            MyInputManager.instance.inputMap.delete("mouseRight");
        }
    }

    private onMouseLockChange(e: Event): void {
        if (document.pointerLockElement === MyInputManager.instance.canvas) {
            MyInputManager.instance.input.mouseLocked = true;
        } else {
            MyInputManager.instance.input.mouseLocked = false;
            MyInputManager.instance.inputMap.clear();
            GameManager.uiEvent.get("openMenu").dispatch();
        }
    }

    private updateFromKeyboard(): void {
        if (this.inputMap.has("w")) {
            this.input.playerMovement.y = 1;
        } else if (this.inputMap.has("s")) {
            this.input.playerMovement.y = -1;
        } else {
            this.input.playerMovement.y = 0;
        }

        if (this.inputMap.has("d")) {
            this.input.playerMovement.x = 1;
        } else if (this.inputMap.has("a")) {
            this.input.playerMovement.x = -1;
        } else {
            this.input.playerMovement.x = 0;
        }

        this.input.playerJump = this.inputMap.has(" ");
        this.input.playerMovement.normalize();

        if (this.isJustPressed("e")) {
            GameManager.uiEvent.get("openBlocks").dispatch();
        }
    }

    public exitPointerLock(): void {
        try {
            MyInputManager.instance.inputMap.clear();
            this.input.mouseLocked = false;
            document.exitPointerLock();
        } catch {
            return;
        }
    }

    public enterPointerLock(): void {
        try {
            MyInputManager.instance.canvas.requestPointerLock();
            MyInputManager.instance.canvas.focus();
            MyInputManager.instance.canvas.tabIndex = 1;
            MyInputManager.instance.input.mouseLocked = false;
        } catch {
            return;
        }
    }

    private updateFromMouse(): void {
        this.input.place = this.isJustPressed("mouseRight");
        this.input.destroy = this.isJustPressed("mouseLeft");

        this.input.mouseMovement.x = this.mouseData.movement.x;
        this.input.mouseMovement.y = this.mouseData.movement.y;
        this.mouseData.movement.set(0, 0);

        // End
        if (this.isJustPressed("mouseLeft")) {
            if (!this.input.mouseLocked) {
                this.canvas.requestPointerLock();
            }
        }
    }

    public dispose(): void {
        document.removeEventListener("keydown", this.onKeyDown);
        document.removeEventListener("keyup", this.onKeyUp);
        this.canvas.removeEventListener("mousemove", this.onMouseMove);
        this.canvas.removeEventListener("pointerup", this.onMouseUp);
        this.canvas.removeEventListener("pointerdown", this.onMouseDown);
        document.removeEventListener("pointerlockchange", this.onMouseLockChange);

        MyInputManager._instance = null;
    }
}
