import Vue from "vue";

type Cookie = "miniworlds_locale" | "miniworlds_session" | "miniworlds_dark";

export default class CookieManager {
  public static get<T>(cookie: Cookie): T | undefined {
    return Vue.$cookies.get(cookie) as T | undefined;
  }

  public static set(
    cookie: Cookie,
    value: unknown,
    duration: number = 24 * 60 * 60
  ): void {
    const endDate = new Date();
    endDate.setSeconds(endDate.getSeconds() + duration);
    Vue.$cookies.set(cookie, value, endDate);
  }

  public static remove(cookie: Cookie): void {
    Vue.$cookies.remove(cookie);
  }
}
