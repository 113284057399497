






























































import Vue from "vue";

import { post } from "#root/lib/utils";
import Game from "#root/game/game";
const MainAlert = () => import("#root/components/generic/MainAlert.vue");
import SessionModule from "#root/store/session";

import { UserProfile } from "#mws/interfaces";
import { LocaleKeys, Endpoints } from "#mws/data";
import AppModule from "./store/app";
import PersistentModule from "./store/persistent";
import Store from "./store/store";
import { MainAlertData } from './lib/interfaces';

export default Vue.extend({
  name: "App",
  components: { MainAlert },
  computed: {
    alert(): MainAlertData {
      return this.$store.getters["app/alert"];
    },
  },
  created() {
    Game.init();
  },
  methods: {
    connect(): void {
      post<undefined, UserProfile | undefined>({
        endpoint: Endpoints.HTTP.user.profile.get,
        auth: true,
      })
        .then((result) => {
          if (result) {
            SessionModule.profile = result;
          }
          AppModule.loadingAlert();
          this.$router.push("/menu");
        })
        .catch((error) => {
          if (error === LocaleKeys.server.auth.error.invalidToken) return;

          AppModule.setAlertButton(
            {
              text: { key: LocaleKeys.alert.retry },
              color: "primary",
              action: this.connect,
            },
            0
          );
          AppModule.setAlertButton(
            {
              text: { key: LocaleKeys.alert.logout },
              color: "error",
              action: () => {
                Store.logout();
                this.$router.push("/login");
                AppModule.loadingAlert();
              },
            },
            1
          );
        });
    },
  },
  mounted() {
    if (PersistentModule.session) {
      this.connect();
    } else {
      this.$router.push("/login");
    }
  },
});
