import store from "#root/store/realStore/index";
import assert from "assert";
import { AuthSession } from "#mws/interfaces";
import { Translation } from "#root/lib/interfaces";

export default class PersistentModule {
  public static init(): void {
    store.commit("persistent/init");
  }

  public static get locale(): string {
    return store.getters["persistent/locale"];
  }
  public static set locale(value: string) {
    store.commit("persistent/locale", value);
  }

  public static get session(): AuthSession | undefined {
    return store.getters["persistent/session"];
  }
  public static set session(value: AuthSession | undefined) {
    assert(value !== undefined);

    store.commit("persistent/session", value);
  }

  public static translate(translation: Translation): string {
    return store.getters["persistent/translate"](translation);
  }

  public static clear(): void {
    store.commit("persistent/clearSession");
  }
}
