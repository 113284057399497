import CookieManager from "#root/lib/cookieManager";
import VueI18n from "vue-i18n";
import { Module } from "vuex";
import { AuthSession } from "#mws/interfaces";
import store from "#root/store/realStore/index";
import { getUnixSeconds } from "#mws/utils";
import localeTable from "#root/lib/localeTable";
import { Translation } from "#root/lib/interfaces";

export const persistentModule: Module<any, unknown> = {
  namespaced: true,
  state: () => ({
    i18n: undefined,
    session: undefined,
  }),
  getters: {
    locale(state: any) {
      return state.i18n.locale;
    },
    session(state: any) {
      if (state.session === null) return null;

      if (state.session.expiration < getUnixSeconds()) {
        state.session = null;
        store.commit("persistent/clearSession");
        return null;
      }
      return state.session;
    },
    translate: (state: any) => (translation: Translation) => {
      return state.i18n.t(
        translation.key,
        translation.overrideLang || state.i18n.locale,
        translation.values
      );
    },
  },
  mutations: {
    init(state: any) {
      const locale =
        CookieManager.get<string>("miniworlds_locale") ||
        (navigator.language in localeTable ? navigator.language : "en-US");
      state.i18n = new VueI18n({
        locale,
        messages: localeTable,
      });
      state.session = CookieManager.get<AuthSession>("miniworlds_session");
    },
    locale(state: any, locale: string): void {
      state.i18n.locale = locale;
      CookieManager.set("miniworlds_locale", locale);
    },
    session(state: any, session: AuthSession): void {
      state.session = session;
      CookieManager.set(
        "miniworlds_session",
        session,
        session.expiration - getUnixSeconds()
      );
    },
    clearSession(state: any): void {
      CookieManager.remove("miniworlds_session");
      state.session = null;
    },
  },
};
