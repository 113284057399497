import Vue from "vue";
import Vuex from "vuex";
import { appModule } from "#root/store/realStore/app";
import { persistentModule } from "#root/store/realStore/persistent";
import { sessionModule } from "#root/store/realStore/session";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    persistent: persistentModule,
    app: appModule,
    session: sessionModule,
  },
  mutations: {
    logout(): void {
      this.commit("persistent/clearSession");
      this.commit("session/clear");
    },
  },
});
