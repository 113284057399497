import { MainAlertData } from "#root/lib/interfaces";
import { Module } from "vuex";

export const appModule: Module<any, unknown> = {
  namespaced: true,
  state: () => ({
    alert: { shown: true, loading: true },
  }),
  getters: {
    alert(state: any) {
      return state.alert;
    },
  },
  mutations: {
    alert(state: any, payload: MainAlertData) {
      state.alert = payload;
    },
  },
};
